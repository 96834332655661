






import Vue from 'vue'
import Login from '../../components/auth/Login.vue'
export default Vue.extend({
    name: "AuthLogin",
    components: {
        Login
    },
    metaInfo(){
      return  {
      title: "Login - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Welome to Our Nation Express, the best courier service in Jamaica. We serve over 200 clients weekly, providing delivery services to Montego Bay and Kingston. Sign up now to enjoy our cheap rates!"
        }
      ]
    };
    } 
})
