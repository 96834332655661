






































































































































































import Vue from 'vue';
import { FormRules } from '@/utils/formRules';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'Login',
  mixins: [FormRules],
  data() {
    return {
      show: false,
      loading: false,
      email: '',
      password: '',
      fieldError: [] as string[],
      redirect: undefined,
    };
  },
  computed: {
    accessToken(): string | null {
      return localStorage.getItem('access_token');
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        const { query } = route;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),

    goToSignUpPage() {
      this.$router.push({ name: 'sign-up' });
    },
    goToForgetPasswordPage() {
      this.$router.push({ name: 'forget-password' });
    },

    goToRatesSheet() {
      this.$router.push({ name: 'rates-list', params: { pagetype: 'page' } });
    },

    loginToWebsite() {
      this.loading = true;
      this.login({ email: this.email, password: this.password })
        .then((data) => {
          this.$router.push({
            path: this.redirect || '/',
          });
        })
        .catch((error: any) => {
          this.loading = false;
          this.fieldError = ['Email or Password is incorrect'];
        });
    },
  },
  mounted() {
    this.show = true;
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('user');
  },
});
